import React from 'react'

import Resume from '../components/resume'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Alex Cam Liu" />
    <Resume />
  </Layout>
)

export default IndexPage
