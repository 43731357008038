import React, { useRef, useState, useEffect, ReactElement } from 'react'
import styled from 'styled-components'
import Loading from '../loading'
import Header from './header'
import Work from './work'
import Portfolio from './portfolio'
import Education from './education'
import Contact from './contact'

const StyledResume = styled.div`
  .content {
    margin-top: 5rem;
  }
`

const ResumeIndex = (): ReactElement => {
  const [show, setShow] = useState(false)
  const workRef = useRef(null)
  const portfolioRef = useRef(null)
  const educationRef = useRef(null)
  const contactRef = useRef(null)

  const componentRefs = {
    workRef,
    portfolioRef,
    educationRef,
    contactRef,
  }

  // in PROD, font-awesome appears really big before getting
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true)
    }, 750)
    return () => clearTimeout(timer)
  }, [])

  if (!show) {
    return <Loading />
  }

  return (
    <StyledResume>
      <Header componentRefs={componentRefs} />
      <div className="content">
        <Work forwardRef={workRef} />
        <Portfolio forwardRef={portfolioRef} />
        <Education forwardRef={educationRef} />
        <Contact forwardRef={contactRef} />
      </div>
    </StyledResume>
  )
}
ResumeIndex.displayName = 'ResumeIndex'

export default ResumeIndex
