import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithubAlt, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faIdCardAlt } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ContactJsonConnection } from '../../generated/types'
import { Box, StyledTitle, colors, breakpoints } from '../../styles'

const ContactName = {
  GITHUB: 'GITHUB',
  GMAIL: 'GMAIL',
  LINKEDIN: 'LINKEDIN',
}

const AccounType = {
  ACCOUNT: 'ACCOUNT',
  EMAIL: 'EMAIL',
}

const StyledContactContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px 10%;

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: 0;
  }
`

const StyledBox = styled(Box)`
  min-width: 60%;
  margin: 20px;

  .body {
    padding: 20px;
    text-align: center;

    & svg {
      border: 2px solid ${colors.tangerine};
      padding: 10px;
      border-radius: 50%;
      margin: 0 1.5rem;
      color: ${colors.teal};
      background: ${colors.lighttan};
      border-color: ${colors.lighttan};
      font-size: 1.4rem;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 10px ${colors.teal};
      }
    }

    // fix issue with box-sizing from prime react
    * {
      box-sizing: content-box !important;
    }
  }
`
StyledBox.displayName = 'Contact-StyledBox'

interface Props {
  forwardRef: React.MutableRefObject<HTMLDivElement>
}

interface ContactData {
  data: ContactJsonConnection
}

const getAccountLinkIcon = (name: string): IconDefinition => {
  let icon = faIdCardAlt
  if (name === ContactName.GITHUB) icon = faGithubAlt
  if (name === ContactName.GMAIL) icon = faEnvelope
  if (name === ContactName.LINKEDIN) icon = faLinkedinIn

  return icon as IconDefinition
}

const ResumeContact = ({ forwardRef }: Props): ReactElement => {
  const {
    data: { nodes },
  } = useStaticQuery<ContactData>(graphql`
    query {
      data: allContactJson {
        nodes {
          id
          name
          type
          reference
        }
      }
    }
  `)
  return (
    <>
      <StyledTitle ref={forwardRef}>
        <FontAwesomeIcon icon={faIdCardAlt as IconDefinition} /> - Contact
      </StyledTitle>
      <StyledContactContainer>
        <StyledBox>
          <div className="body">
            {nodes.map((d, index) => {
              if (d.type === AccounType.EMAIL) {
                return (
                  <a href={`mailto:${d.reference}`} aria-label={`Mail to ${d.name}`} key={index}>
                    <FontAwesomeIcon icon={getAccountLinkIcon(d.name)} />
                  </a>
                )
              }
              if (d.type === AccounType.ACCOUNT) {
                return (
                  <a
                    href={d.reference}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`${d.name} account link`}
                    key={index}
                  >
                    <FontAwesomeIcon icon={getAccountLinkIcon(d.name)} />
                  </a>
                )
              }
              return null
            })}
          </div>
        </StyledBox>
      </StyledContactContainer>
    </>
  )
}
ResumeContact.displayName = 'ResumeContact'

export default ResumeContact
