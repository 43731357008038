import styled from 'styled-components'

export const colors = {
  darkgray: '#363636',
  tangerine: '#8d4511',
  tan: '#eeaa7b',
  lighttan: '#dfe2de',
  teal: '#085b68',
}

export const breakpoints = {
  mobile: '600px',
  tablet: '862px',
  lg: '1602px',
}

export const Box = styled.div`
  border: 1px solid ${colors.lighttan};
  border-radius: 2px;
  -webkit-box-shadow: 10px 10px 10px -6px #777;
  -moz-box-shadow: 10px 10px 10px -6px #777;
  box-shadow: 10px 10px 10px -6px #777;

  .heading {
    background: ${colors.lighttan};
  }

  .body {
    font-size: 1rem;
  }

  .footer {
    font-size: 0.75rem;
    background: ${colors.lighttan};
  }
`
Box.displayName = 'Box'

export const StyledTitle = styled.h1`
  > * {
    display: inline;
  }
  padding: 20px;
  background-color: ${colors.lighttan};
  color: ${colors.teal};
  text-align: center;
  font-weight: 800;
  font-size: 1.3rem;
`
StyledTitle.displayName = 'StyledTitle'
