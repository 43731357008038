import React, { ReactElement } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import cn from 'classnames'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { PortfolioJsonConnection } from '../../generated/types'
import { Box, StyledTitle, colors, breakpoints } from '../../styles'

const StyledPortfolioContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 25px 10%;

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: 0;
  }
`

const StyledBox = styled(Box)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    width: 40%;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    width: 23%;
  }

  &:hover {
    box-shadow: 5px 5px 20px ${colors.teal};
  }

  width: 80%;
  margin: 10px;

  a:link {
    color: inherit;
    text-decoration: none;
  }
  a:visited {
    color: inherit;
    text-decoration: none;
  }

  .image {
    position: relative;
    height: 250px;
    padding: 10px;
    border-bottom: 1px solid ${colors.lighttan};

    img {
      max-height: 80%;
      max-width: 80%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .description {
    padding: 10px;

    p:first-child {
      font-weight: 800;
      color: ${colors.teal};
    }
  }

  .replay {
    background-color: #374b67;
  }
`
StyledPortfolioContainer.displayName = 'Portofilo-StyledPortfolioContainer'

interface Props {
  forwardRef: React.MutableRefObject<HTMLDivElement>
}

interface PortfolioData {
  data: PortfolioJsonConnection
}

const ResumePortfolio = ({ forwardRef }: Props): ReactElement => {
  const {
    data: { nodes },
  } = useStaticQuery<PortfolioData>(
    graphql`
      query {
        data: allPortfolioJson {
          nodes {
            description
            id
            link
            name
            image {
              src {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <StyledTitle ref={forwardRef}>
        <FontAwesomeIcon icon={faLightbulb as IconDefinition} /> - Portfolio
      </StyledTitle>
      <StyledPortfolioContainer>
        {nodes.map((d, index) => (
          <StyledBox key={index}>
            <a href={d.link} target="_blank" rel="noopener noreferrer">
              <div className={cn('image', { replay: d.name === 'Purdue Replay' })}>
                <Img
                  style={{ height: '100%', width: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                  fluid={d.image.src.childImageSharp.fluid}
                  alt={d.name}
                ></Img>
              </div>
              <div className="description">
                <p>{d.name}</p>
                <p>{d.description}</p>
              </div>
            </a>
          </StyledBox>
        ))}
      </StyledPortfolioContainer>
    </>
  )
}
ResumePortfolio.displayName = 'Portofilo'

ResumePortfolio.propTypes = {
  forwardRef: PropTypes.object.isRequired,
}

export default ResumePortfolio
