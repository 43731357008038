import React, { ReactElement } from 'react'
import { keys, capitalize } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { WorkJsonConnection } from '../../generated/types'
import { Box, StyledTitle, colors, breakpoints } from '../../styles'

const StyledWorkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 25px 10%;
  & > * {
    margin: 10px;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: 0;
  }
`

const StyledBox = styled(Box)`
  width: 85%;
  .heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px;
    color: ${colors.teal};

    & span {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;

      &:last-child {
        font-size: 0.85rem;
        margin-top: 3px;
        font-style: italic;
      }
    }
  }

  .body {
    padding: 20px;
    ul li:not(:first-child) {
      margin-top: 10px;
    }

    .relevant-skill {
      color: ${colors.tangerine};
      font-weight: 600;
    }
  }

  .footer {
    padding: 5px;

    & label {
      font-size: 0.85rem;
    }
  }
`
StyledWorkContainer.displayName = 'Portofilo-StyledWorkContainer'

interface Props {
  forwardRef: React.MutableRefObject<HTMLDivElement>
}

interface WorkData {
  data: WorkJsonConnection
}

const renderSkills = (skills: any) => {
  if (!skills) return null

  const skillKeys = keys(skills).filter((sk: string) => sk !== '__typename')
  return skillKeys.map((sk: string, index: number) => (
    <li key={index}>
      Technologies used:
      <ul>
        <li key={index}>
          <strong>{capitalize(sk)}: </strong>
          {skills[sk].map((s: any, index: number) => (
            <span key={index} className={s.isRelevant ? 'relevant-skill' : ''}>
              {`${s.name}${skills[sk].length !== index + 1 ? ', ' : '.'}`}
            </span>
          ))}
        </li>
      </ul>
    </li>
  ))
}

const ResumeWork = ({ forwardRef }: Props): ReactElement => {
  const {
    data: { nodes },
  } = useStaticQuery<WorkData>(
    graphql`
      query {
        data: allWorkJson {
          nodes {
            id
            place
            team
            title
            from
            to
            duties
            skills {
              backend {
                isRelevant
                name
              }
              frontend {
                isRelevant
                name
              }
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <StyledTitle ref={forwardRef}>
        <FontAwesomeIcon icon={faSuitcase as IconDefinition} /> - Work Experience
      </StyledTitle>
      <StyledWorkContainer>
        {nodes.map((job, index) => (
          <StyledBox key={index}>
            <div className="heading">
              <span>{job.title}</span>
              <span>
                {job.place} - {job.team}
              </span>
            </div>
            <div className="body">
              <ul>
                {job.duties.map((duty, index) => (
                  <li key={index}>{duty}</li>
                ))}
                {renderSkills(job.skills)}
              </ul>
            </div>
            <div className="footer">
              <span>
                {job.from} - {job.to || 'Present'}
              </span>
            </div>
          </StyledBox>
        ))}
      </StyledWorkContainer>
    </>
  )
}
ResumeWork.displayName = 'ResumeWork'

ResumeWork.propTypes = {
  forwardRef: PropTypes.object.isRequired,
}

export default ResumeWork
