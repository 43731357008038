/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles'

const StyledContent = styled.div`
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  footer {
    text-align: center;
    color: ${colors.darkgray};
  }
`

const Layout: React.FC = ({ children }) => {
  return (
    <StyledContent>
      <div>
        <main>{children}</main>
        <footer>
          <span>Copyright © Alexander Cam Liu - {new Date().getFullYear()}</span>
        </footer>
      </div>
    </StyledContent>
  )
}

export default Layout
