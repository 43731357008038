import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { EducationJsonConnection } from '../../generated/types'
import { Box, StyledTitle, colors, breakpoints } from '../../styles'

const StyledResumeEducationContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 25px 10%;
  & > * {
    margin: 10px;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    margin: 0;
  }
`
StyledResumeEducationContainer.displayName = 'StyledResumeEducationContainer'

const StyledBox = styled(Box)`
  width: 48%;

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 90%;
  }

  .body {
    padding: 20px;

    & .school {
      font-weight: 700;
      font-size: 1rem;
      color: ${colors.teal};
    }

    & .degree {
      font-weight: 500;
      font-size: 0.9rem;
      color: ${colors.tangerine};
    }

    & .time {
      font-size: 0.9rem;
    }
  }
`
StyledBox.displayName = 'StyledBox'

interface Props {
  forwardRef: React.MutableRefObject<HTMLDivElement>
}

interface EducationData {
  data: EducationJsonConnection
}

const ResumeEducation = ({ forwardRef }: Props): ReactElement => {
  const {
    data: { nodes },
  } = useStaticQuery<EducationData>(
    graphql`
      query {
        data: allEducationJson {
          nodes {
            degree
            from
            id
            place
            to
          }
        }
      }
    `,
  )
  return (
    <>
      <StyledTitle ref={forwardRef}>
        <FontAwesomeIcon icon={faGraduationCap as IconDefinition} /> - Education
      </StyledTitle>
      <StyledResumeEducationContainer>
        {nodes.map((d, index) => (
          <StyledBox key={index}>
            <div className="body">
              <p className="school">{d.place}</p>
              <p className="degree">{d.degree}</p>
              <p className="time">
                {d.from}-{d.to || 'present'}
              </p>
            </div>
          </StyledBox>
        ))}
      </StyledResumeEducationContainer>
    </>
  )
}
ResumeEducation.displayName = 'ResumeEducation'

ResumeEducation.propTypes = {
  forwardRef: PropTypes.object.isRequired,
}

export default ResumeEducation
