import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faGraduationCap,
  faIdCardAlt,
  faLightbulb,
  faSuitcase,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { ScrollTo } from 'react-scroll-to'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { breakpoints, colors } from '../../styles'

const StyledResumeHeader = styled.header`
  display: flex;

  .info-container {
    width: 50%;

    /* Name */
    & span:first-child {
      text-align: center;
      display: block;
      margin: 1rem 0;
      font-size: 2rem;
      font-weight: 600;
      color: ${colors.teal};
    }

    & span:nth-child(2) {
      text-align: center;
      display: block;
      font-size: 1.3rem;
      font-style: italic;
      color: ${colors.tangerine};
      font-weight: 600;
    }

    nav {
      margin: 2.15rem 0;
      text-align: center;

      & svg {
        border: 1px solid black;
        padding: 10px;
        border-radius: 50%;
        margin: 0 1.5rem;
        color: ${colors.teal};
        background: ${colors.lighttan};
        border-color: ${colors.lighttan};
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 10px ${colors.teal};
        }
      }
    }
  }

  .profile-picture {
    border: 3px solid ${colors.lighttan};
    border-radius: 40%;
    height: 140px;
    width: 140px;
    margin: 2.5rem 0;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .divider {
    margin: 1rem 0;
    height: 1px;
    background-color: ${colors.lighttan};
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    .info-container {
      width: 50%;

      &:first-child {
        padding: 10px 0 25px 45%;
      }

      &:last-child {
        display: none;
      }
    }

    .profile-picture {
      margin-left: 10px;
      top: -15px;
      left: 15px;
    }

    .divider {
      display: none;
    }
  }
`
StyledResumeHeader.displayName = 'StyledResumeHeader'

const StyledDivider = styled.hr`
  margin: 1rem 0;
  height: 1px;
  background-color: ${colors.lighttan};

  @media screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`

const scrollTo = (scroll, offsetTop) => {
  scroll({
    y: offsetTop,
    smooth: true,
  })
}

interface Props {
  componentRefs: {
    workRef: React.MutableRefObject<HTMLDivElement>
    portfolioRef: React.MutableRefObject<HTMLDivElement>
    educationRef: React.MutableRefObject<HTMLDivElement>
    contactRef: React.MutableRefObject<HTMLDivElement>
  }
}

export const ResumeHeader: React.FC<Props> = ({ componentRefs }) => {
  const profilePicture = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <StyledResumeHeader>
        <div className="info-container">
          <span>Alexander Cam Liu</span>
          <span>Senior Software Developer</span>
        </div>
        <Img
          fluid={profilePicture?.placeholderImage?.childImageSharp?.fluid}
          alt="Alexander Cam Liu"
          className="profile-picture"
          style={{ position: 'absolute' }}
        />
        <div className="info-container">
          <nav>
            <ScrollTo>
              {({ scroll }) => (
                <>
                  <FontAwesomeIcon
                    data-tip
                    data-for="work"
                    icon={faSuitcase as IconDefinition}
                    onClick={() => scrollTo(scroll, componentRefs.workRef.current.offsetTop)}
                  />
                  <FontAwesomeIcon
                    data-tip
                    data-for="portfolio"
                    icon={faLightbulb as IconDefinition}
                    onClick={() => scrollTo(scroll, componentRefs.portfolioRef.current.offsetTop)}
                  />
                  <FontAwesomeIcon
                    data-tip
                    data-for="education"
                    icon={faGraduationCap as IconDefinition}
                    onClick={() => scrollTo(scroll, componentRefs.educationRef.current.offsetTop)}
                  />
                  <FontAwesomeIcon
                    data-tip
                    data-for="contact"
                    icon={faIdCardAlt as IconDefinition}
                    onClick={() => scrollTo(scroll, componentRefs.contactRef.current.offsetTop)}
                  />
                </>
              )}
            </ScrollTo>
          </nav>
        </div>
      </StyledResumeHeader>
      <StyledDivider />

      {/* Tooltips */}
      <ReactTooltip
        id="work"
        place="bottom"
        effect="solid"
        backgroundColor="transparent"
        textColor={colors.tangerine}
      >
        <strong>Work Experience</strong>
      </ReactTooltip>
      <ReactTooltip
        id="portfolio"
        place="bottom"
        effect="solid"
        backgroundColor="transparent"
        textColor={colors.tangerine}
      >
        <strong>Portfolio</strong>
      </ReactTooltip>
      <ReactTooltip
        id="education"
        place="bottom"
        effect="solid"
        backgroundColor="transparent"
        textColor={colors.tangerine}
      >
        <strong>Education</strong>
      </ReactTooltip>
      <ReactTooltip
        id="contact"
        place="bottom"
        effect="solid"
        backgroundColor="transparent"
        textColor={colors.tangerine}
      >
        <strong>Contact</strong>
      </ReactTooltip>
    </>
  )
}
ResumeHeader.displayName = 'ResumeHeader'

export default ResumeHeader
